const template = document.createElement("template");

template.innerHTML = `
  <style>
    @keyframes slide-in {
      from { transform: translateY(100%) }
    }

    @keyframes slide-out {
      to { transform: translateY(100%) }
    }

    :host {
      --duration: 300ms;
      --max-width: none;
      --min-width: 100%;
      animation: slide-in var(--duration) ease-out;
      box-sizing: border-box;
      display: block;
      max-width: var(--max-width);
      min-width: var(--min-width);
      padding: 0 20px 24px;
      width: 100%;
    }

    :host([hidden])  {
      animation: slide-out var(--duration) ease-out;
    }

    #container {
      align-items: center;
      background: var(--ws-snackbar-background, #333);
      border-radius: var(--ws-snackbar-border-radius);
      box-shadow: var(--ws-snackbar-box-shadow);
      box-sizing: border-box;
      display: flex;
      gap: 12px;
      justify-content: space-between;
      padding: var(--ws-snackbar-padding);
    }

    #label {
      color: var(--ws-snackbar-label-color, #fff);
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
      text-align: left;
    }

    #label:has(+ #action:empty) {
      text-align: center;
    }

    #action {
      background: none;
      border-radius: var(--ws-snackbar-action-border-radius);
      border: none;
      color: var(--ws-snackbar-action-color, #fff);
      font-size: 1rem;
      font-weight: var(--ws-snackbar-action-font-weight, 400);
      line-height: 1;
      margin: 0;
      padding: 12px;
    }

    #action:empty {
      display: none;
    }

    @media (hover: hover) {
      #action:hover,
      #action:focus-visible {
        background-color: var(--ws-snackbar-action-background-color-hover);
        cursor: pointer;
      }
    }

    #action:active {
      background-color: var(--ws-snackbar-action-background-color-active);
    }

    @media (prefers-reduced-motion: reduce) {
      :host {
        --duration: 0ms;
      }
    }

    @media (min-width: 768px) {
      :host {
        --max-width: 560px;
        --min-width: 440px;
      }
    }
  </style>
  <div id="container">
    <p id="label"></p>
    <button id="action"></button>
  </div>
`;

export type SnackbarMessageOptions = {
  duration?: number;
};

export class SnackbarMessage extends HTMLElement {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  actionCallback = () => {};
  options: SnackbarMessageOptions = {};
  #actionElement: HTMLElement;
  #labelElement: HTMLElement;
  #timeout: number | null = null;

  get action() {
    return this.getAttribute("action") ?? "";
  }

  set action(action) {
    action
      ? this.setAttribute("action", action)
      : this.removeAttribute("action");
  }

  get label() {
    return this.getAttribute("label") ?? "";
  }

  set label(label) {
    label ? this.setAttribute("label", label) : this.removeAttribute("label");
  }

  // eslint-disable-next-line wc/no-constructor-params
  constructor(
    label: string,
    action: string,
    actionCallback: () => void,
    options: SnackbarMessageOptions = {},
  ) {
    super();

    this.label = label ?? this.label;
    this.action = action ?? this.action;
    this.actionCallback = actionCallback ?? this.actionCallback;
    this.#setOptions(options);

    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));

    this.#labelElement = this.shadowRoot!.getElementById("label")!;
    this.#labelElement.textContent = this.label;

    this.#actionElement = this.shadowRoot!.getElementById("action")!;
    if (this.action) {
      this.#actionElement.textContent = this.action;
      this.#actionElement.addEventListener(
        "click",
        this.#handleAction.bind(this),
      );
    }
  }

  #setOptions(options: SnackbarMessageOptions) {
    this.options = {
      duration: options.duration
        ? options.duration
        : this.action === ""
          ? 2000
          : 5000,
    };
  }

  connectedCallback() {
    if (!this.hasAttribute("aria-live")) {
      this.setAttribute("aria-live", "polite");
    }
    this.hidden = true;
  }

  // Shows the snackbar and start the "in" transition.
  show() {
    this.addEventListener(
      "animationend",
      () => {
        this.#timeout = window.setTimeout(
          () => this.hide(),
          this.options.duration,
        );
      },
      { once: true },
    );
    this.hidden = false;
  }

  // Hides the snackbar and start the "out" animation, removes the component after the animation is finished.
  hide(): Promise<void> {
    return new Promise((resolve) => {
      this.addEventListener(
        "animationend",
        (event) => {
          if (event.target !== this) {
            return resolve();
          }
          this.remove();
          resolve();
        },
        { once: true },
      );
      if (this.#timeout) {
        clearTimeout(this.#timeout);
      }
      this.hidden = true;
    });
  }

  disconnectedCallback() {
    this.#actionElement.removeEventListener(
      "click",
      this.#handleAction.bind(this),
    );
  }

  // Handles the action click event.
  #handleAction() {
    this.actionCallback();
    this.hide();
  }
}

"customElements" in window &&
  customElements.get("ws-snackbar-message") === undefined &&
  customElements.define("ws-snackbar-message", SnackbarMessage);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-snackbar-message": SnackbarMessage;
  }
}
