import { Player } from "./media_player.js";
import { PluginGa4 } from "./media_plugin-ga4.js";
import { AudioConfig } from "./media_audio-config.js";

export class AudioPlayer extends Player {
  protected readonly config: AudioConfig;

  public constructor(element: HTMLElement) {
    super(element);
    this.config = new AudioConfig(this.media);
  }

  protected registerPlugins(): void {
    this.plugins.add(new PluginGa4(this.media, "audio"));
  }
}
