import { DRAWER_CLOSED } from "./drawer.js";

class Menu extends HTMLElement {
  #listButtons: HTMLButtonElement[] = [];
  #listButtonsExpandedSelector = '[aria-expanded="true"]';

  connectedCallback() {
    // Cache elements
    this.#listButtons = Array.from(this.querySelectorAll("[aria-expanded]"));
    // Add event listeners
    this.#listButtons.forEach((listButton) =>
      listButton.addEventListener("click", this.#handleListButtonClick),
    );
    document.addEventListener(DRAWER_CLOSED, this.#handleDrawerClose);
  }

  disconnectedCallback() {
    // Clean up event listeners
    this.#listButtons.forEach((listButton) =>
      listButton.removeEventListener("click", this.#handleListButtonClick),
    );
    document.removeEventListener(DRAWER_CLOSED, this.#handleDrawerClose);
  }

  #handleDrawerClose = () => {
    this.#closeLists(
      Array.from(this.querySelectorAll(this.#listButtonsExpandedSelector)),
    );
  };

  #handleListButtonClick = (event: Event) => {
    if (event.target instanceof HTMLButtonElement) {
      this.#updateElementAttributes(event.target);
      this.#closeAllChildListsIfNeeded(event.target);
    }
  };

  #updateElementAttributes(element: HTMLElement, forcedValue = false) {
    const value =
      forcedValue || !(element.getAttribute("aria-expanded") === "true");
    element.setAttribute("aria-expanded", value ? "true" : "false");
    element.setAttribute("aria-label", value ? "Zuklappen" : "Aufklappen");
  }

  #closeLists(buttonElements: HTMLButtonElement[]) {
    buttonElements.forEach((buttonElement) =>
      this.#updateElementAttributes(buttonElement, false),
    );
  }

  #closeAllChildListsIfNeeded(buttonElement: HTMLButtonElement) {
    const parentElement = buttonElement.parentNode;
    if (
      parentElement &&
      buttonElement.getAttribute("aria-expanded") === "false"
    ) {
      this.#closeLists(
        Array.from(
          parentElement.querySelectorAll(this.#listButtonsExpandedSelector),
        ),
      );
    }
  }
}

customElements.get("ws-menu") ?? customElements.define("ws-menu", Menu);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-menu": Menu;
  }
}
