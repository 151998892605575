import { OPEN_PAYWALL_DIALOG } from "./gallery";

export const PAYWALL_OPENED_EVENT = "paywall-opened";

export class PaywallControl extends HTMLElement {
  connectedCallback() {
    const restrictedElements = this.querySelectorAll(".ws-open-dialog"),
      dialog = document.querySelector("dialog");

    if (restrictedElements.length && dialog) {
      restrictedElements.forEach((element) => {
        element.addEventListener("click", function (event) {
          event.preventDefault();
          dialog.showModal();
          document.dispatchEvent(new CustomEvent(PAYWALL_OPENED_EVENT));
        });
      });
    }

    document.addEventListener(OPEN_PAYWALL_DIALOG, this.handleOpenModal);
  }

  handleOpenModal = () => {
    const dialog = document.querySelector("dialog");
    if (dialog) {
      dialog.showModal();
      document.dispatchEvent(new CustomEvent(PAYWALL_OPENED_EVENT));
    }
  };

  disconnectedCallback() {
    document.removeEventListener(OPEN_PAYWALL_DIALOG, this.handleOpenModal);
  }
}

"customElements" in window &&
  customElements.get("ws-paywallcontrol") === undefined &&
  customElements.define("ws-paywallcontrol", PaywallControl);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-paywallcontrol": PaywallControl;
  }
}
