/**
 * Tracking provider interface for VG Wort - collection and distribution of
 * royalties to authors and publishers for the use of their written works
 * @see https://www.vgwort.de/
 */
export class VgWort extends HTMLElement {
  // get url attribute
  get url(): string {
    return this.getAttribute("url") ?? "";
  }

  // get key attribute
  get key(): string {
    return this.getAttribute("key") ?? "";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.shadowRoot!.innerHTML = `<img src="${this.url}${this.key}" height="1" width="1" border="0" alt="">`;
  }
}

customElements.get("ws-vgwort") ?? customElements.define("ws-vgwort", VgWort);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-vgwort": VgWort;
  }
}
