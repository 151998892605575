import { isElementWithViewport } from "./utils.js";

export class Magnet extends HTMLElement {
  #intersectionObserver: IntersectionObserver;

  get targetSelector() {
    return this.getAttribute("target-selector");
  }

  constructor() {
    super();

    const root = isElementWithViewport(this.parentElement)
      ? this.parentElement.getViewportElement()
      : null;

    // Setup IntersectionObserver
    this.#intersectionObserver = new IntersectionObserver(
      this.#handleIntersection,
      {
        root,
        rootMargin: "0px",
        threshold: 1.0,
      },
    );
  }

  connectedCallback() {
    this.#intersectionObserver.observe(this);
  }

  #handleIntersection = (entries: IntersectionObserverEntry[]) => {
    for (const entry of entries) {
      if (!entry.isIntersecting) return;
      if (!this.targetSelector) return;
      const targets = document.querySelectorAll(this.targetSelector);
      for (const target of targets) {
        if (this.contains(target)) return;
        this.appendChild(target);
        target.removeAttribute("disabled");
      }
    }
  };

  disconnectedCallback() {
    this.#intersectionObserver.disconnect();
  }
}

"customElements" in window &&
  customElements.get("ws-magnet") === undefined &&
  customElements.define("ws-magnet", Magnet);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-magnet": Magnet;
  }
}
