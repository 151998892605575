import { SnackbarMessage, SnackbarMessageOptions } from "./snackbar-message";

const template = document.createElement("template");

template.innerHTML = `
  <style>
    :host {
      bottom: 0;
      contain: content;
      display: block;
      left: 0;
      position: fixed;
      right: 0;
      width: 100%;
    }

    #container {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  </style>
  <slot id="container"></slot>
`;

export class Snackbar extends HTMLElement {
  #nextMessage: SnackbarMessage | null = null;

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.shadowRoot!.appendChild(template.content.cloneNode(true));
  }

  async show(
    label: string,
    action: string,
    callback: () => void,
    options?: SnackbarMessageOptions,
  ) {
    const currentElement = this.firstElementChild;
    const snackbarMessage = new SnackbarMessage(
      label,
      action,
      callback,
      options,
    );

    if (!currentElement) {
      this.appendChild(snackbarMessage);
      snackbarMessage.show();
    } else {
      this.#nextMessage = snackbarMessage;
      if (currentElement instanceof SnackbarMessage && !currentElement.hidden) {
        await currentElement.hide();
        this.appendChild(this.#nextMessage);
        this.#nextMessage.show();
      }
    }
  }
}

"customElements" in window &&
  customElements.get("ws-snackbar") === undefined &&
  customElements.define("ws-snackbar", Snackbar);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-snackbar": Snackbar;
  }
}
