import { polyfillScrollendEvent } from "./utils.js";

const template = document.createElement("template");

template.innerHTML = `
  <style>
    :host {
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-areas: "previous . next";
    }

    :host([hidden]) {
        display: none;
    }

    slot[name="previous"]::slotted(*),
    slot[name="next"]::slotted(*) {
        align-self: center;
        cursor: pointer;
        z-index: 1; /* Fixes safari layering issue */
    }

    slot[name="previous"]::slotted(*) {
        grid-area: previous;
    }

    slot[name="next"]::slotted(*) {
        grid-area: next;
    }

    slot:not([name])::slotted(*) {
        grid-area: 1 / 1 / -1 / -1;
        mask-image: var(--ws-scroller-mask-image);
    }

    .hidden {
        visibility: hidden;
    }
  </style>
  <slot></slot>
  <slot name="previous"></slot>
  <slot name="next"></slot>
`;

export class Scroller extends HTMLElement {
  #slots: Record<string, HTMLSlotElement> = {};
  #content: Element;
  #item: Element;
  #itemWidth: number;
  #oneSlidePerMove: boolean;
  #gap: number;

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));
    this.#slots.previous = this.shadowRoot!.querySelector(
      `slot[name="previous"]`,
    ) as HTMLSlotElement;
    this.#slots.next = this.shadowRoot!.querySelector(
      `slot[name="next"]`,
    ) as HTMLSlotElement;
    this.#slots.default = this.shadowRoot!.querySelector(
      `slot:not([name])`,
    ) as HTMLSlotElement;
    this.#content = this.#slots.default.assignedElements()[0]!;
    this.#item = this.#content.querySelector(".scroller__item")!;
    this.#itemWidth = 0;
    this.#oneSlidePerMove = false;
    const contentStyle = getComputedStyle(this.#content);
    if (contentStyle.display === "flex") {
      this.#gap = parseFloat(contentStyle.gap);
    } else {
      this.#gap = 0;
    }
  }

  connectedCallback() {
    this.#oneSlidePerMove = this.hasAttribute("one-slide-per-move");
    /* get prev and next button */
    const previousSlot = this.#slots.previous!;
    const nextSlot = this.#slots.next!;

    /* set buttons initial on display none to prevent layout shift */
    previousSlot.style.display = "none";
    nextSlot.style.display = "none";

    /* delay function and displaying function 'til content is fully loaded (100ms is currently alright) */
    setTimeout(() => {
      this.#updateButtonsVisibility();
      previousSlot.style.display = "";
      nextSlot.style.display = "";
    }, 100);

    window.addEventListener("resize", this.#updateButtonsVisibility);
    previousSlot.addEventListener("click", this.#scrollPrevious);
    nextSlot.addEventListener("click", this.#scrollNext);
    if (!("onscrollend" in window)) polyfillScrollendEvent(this.#content); // Polyfills "scrollend" event in safari
    this.#content.addEventListener("scrollend", this.#updateButtonsVisibility);
  }

  get itemWidth() {
    if (!this.#itemWidth) {
      this.#item = this.#content.querySelector(".scroller__item")!;
      this.#itemWidth = this.#item.getBoundingClientRect().width;
    }
    return this.#itemWidth;
  }

  #scrollPrevious = (e: Event) => {
    e.preventDefault();
    this.#content.scrollLeft -= this.#oneSlidePerMove
      ? this.itemWidth + this.#gap
      : this.#content.clientWidth * 0.5;
  };

  #scrollNext = (e: Event) => {
    e.preventDefault();
    this.#content.scrollLeft += this.#oneSlidePerMove
      ? this.itemWidth + this.#gap
      : this.#content.clientWidth * 0.5;
  };

  #updateButtonsVisibility = () => {
    const endScrollThreshold = 5; // on iPhone SE you need to scroll the last 5px to hide the arrow / gradient. imo it's a bad usability therefore this threshold solution
    const scrollLeft = this.#content.scrollLeft || 0;
    const clientWidth = this.#content.clientWidth || 0;
    const scrollWidth = this.#content.scrollWidth || 0;

    const isAtStart = scrollLeft === 0;
    const isAtEnd =
      scrollLeft + clientWidth >= scrollWidth - endScrollThreshold;

    this.#slots.previous!.classList.toggle("hidden", isAtStart);
    this.#slots.next!.classList.toggle("hidden", isAtEnd);

    // Manually set the mask-image value based on the visibility of the slots
    if (!isAtStart && !isAtEnd) {
      (this.#content as HTMLElement).style.setProperty(
        "--ws-scroller-mask-image",
        "var(--ws-scroller-mask-image-both)",
      );
    } else if (isAtStart && !isAtEnd) {
      (this.#content as HTMLElement).style.setProperty(
        "--ws-scroller-mask-image",
        "var(--ws-scroller-mask-image-next)",
      );
    } else if (!isAtStart && isAtEnd) {
      (this.#content as HTMLElement).style.setProperty(
        "--ws-scroller-mask-image",
        "var(--ws-scroller-mask-image-previous)",
      );
    } else {
      (this.#content as HTMLElement).style.setProperty(
        "--ws-scroller-mask-image",
        "none",
      );
    }
  };

  disconnectedCallback() {
    window.removeEventListener("resize", this.#updateButtonsVisibility);
    this.#slots.previous!.removeEventListener("click", this.#scrollPrevious);
    this.#slots.next!.removeEventListener("click", this.#scrollNext);
    this.#content.removeEventListener(
      "scrollend",
      this.#updateButtonsVisibility,
    );
  }
}

"customElements" in window &&
  customElements.get("ws-scroller") === undefined &&
  customElements.define("ws-scroller", Scroller);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-scroller": Scroller;
  }
}
