import type { Token } from "./token";
import type { Snackbar } from "./snackbar";
import type { Bookmarklist } from "./bookmarklist";
import type { BookmarkManager } from "./bookmarkmanager";

const template = document.createElement("template");

template.innerHTML = `
  <style>
    :host {
      display: block;
    }
    :host([hidden]) {
      display: none;
    }
  </style>
  <slot></slot>
`;

export const accessibleNameActive = "Von Merkliste entfernen";
export const accessibleNameInactive = "Zur Merkliste hinzufügen";
export const iconClassActive = "icon-bookmark-filled";
export const iconClassInactive = "icon-bookmark";
export const BOOKMARK_TOGGLE_EVENT = "toggleBookmark";

/**
 * Bookmark button
 * https://rtltech.atlassian.net/wiki/spaces/PAID/pages/212439140/05+Architektur+-+Merkliste
 */
export class BookmarkButton extends HTMLElement {
  static observedAttributes = ["active", "disabled"];

  #bookmarkButton: HTMLElement;
  #bookmarkIcon: HTMLElement;
  #snackbar: Snackbar | null = null;
  #bookmarkList: Bookmarklist | null = null;
  #bookmarkManager: BookmarkManager | null = null;

  get csrfRef(): string {
    return this.getAttribute("csrf-ref") || "";
  }

  get contentId(): string {
    return this.getAttribute("content-id") || "";
  }

  get paidCategory(): string | null {
    return this.getAttribute("paid-category");
  }

  get disabled(): boolean {
    return this.hasAttribute("disabled");
  }

  get active(): boolean {
    return this.hasAttribute("active");
  }

  set active(value) {
    value ? this.setAttribute("active", "") : this.removeAttribute("active");
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));
    this.#bookmarkButton = this.shadowRoot!.querySelector(
      "slot",
    )!.assignedElements()[0]! as HTMLElement;
    this.#bookmarkIcon = this.#bookmarkButton.querySelector("i")!;
  }

  async connectedCallback() {
    this.#setButtonAccessibleNameAndIconClass(this.active);
    this.#registerEvents();
    this.#snackbar = (await this.#getComponent("ws-snackbar")) as Snackbar;
    this.#bookmarkList = (await this.#getComponent(
      "ws-bookmarklist",
    )) as Bookmarklist;
    if (this.#bookmarkList) {
      // Only required on page with bookmarklist
      this.#bookmarkManager = (await this.#getComponent(
        "ws-bookmarkmanager",
      )) as BookmarkManager;
    }
  }

  #registerEvents() {
    this.#bookmarkButton.addEventListener("click", () => {
      if (!this.disabled) {
        this.active ? this.#deleteBookmark() : this.#addBookmark(false);
      }
    });
  }

  async #getComponent(tagName: string) {
    const component = document.querySelector(tagName);
    return await window.customElements.whenDefined(tagName).then(async () => {
      return component;
    });
  }

  async getCSRFToken() {
    const tokenProvider: Token | null = document.querySelector(this.csrfRef);
    if (!tokenProvider) {
      throw new Error(
        "Attribute 'csrf-ref' must point to a valid CSRF token provider.",
      );
    }
    return await window.customElements
      .whenDefined(tokenProvider.tagName.toLowerCase())
      .then(async () => {
        if (!tokenProvider || !("token" in tokenProvider)) {
          throw new Error(
            "Attribute 'csrf-ref' must point to a valid CSRF token provider.",
          );
        }
        // Get token string
        return await tokenProvider.token;
      });
  }

  // Add a bookmark via REST-API (e.g. https://www.dev.stern.de/p-api/lists/bookmarks/items)
  async #addBookmark(skipSnackbar: boolean) {
    this.active = true;
    const apiUrl = `/p-api/lists/bookmarks/items`;
    try {
      const csrfToken = await this.getCSRFToken();
      if (!csrfToken) {
        throw new Error("Request failed: CSRF token is not defined.");
      }
      const response = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify({
          contentId: this.contentId,
          paidCategories: this.paidCategory ? this.paidCategory.split(",") : [],
        }),
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      });
      if (!response.ok) {
        throw new Error(
          `Request failed: ${response.status} - ${response.statusText}`,
        );
      }
      this.#triggerEvent(BOOKMARK_TOGGLE_EVENT, { action: "add" });
      if (!skipSnackbar) {
        const scrollToTop = () => window.scrollTo(0, 0);
        const navigateToBookmarkListPage = () => {
          window.location.href = "/merkliste/";
        };
        const callback = this.#bookmarkList
          ? scrollToTop
          : navigateToBookmarkListPage;
        this.#snackbar?.show(
          "Zur Merkliste hinzugefügt.",
          "Zur Merkliste",
          callback,
        );
      }
      if (this.#bookmarkList) {
        // Update bookmarklist and buttons in teaserblock 'Empfohlene Artikel' on page 'Merkliste'
        await this.#bookmarkList.load();
        this.#bookmarkManager?.updateBookmarkButtons();
      }
    } catch (error) {
      // Reset on error
      this.active = false;
      this.#handleError(error);
    }
  }

  // Delete a bookmark via REST-API (e.g. https://www.dev.stern.de/p-api/lists/bookmarks/items)
  async #deleteBookmark() {
    this.active = false;
    const apiUrl = `/p-api/lists/bookmarks/items/${this.contentId}`;
    try {
      const csrfToken = await this.getCSRFToken();
      if (!csrfToken) {
        throw new Error("Request failed: CSRF token is undefined.");
      }
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      });
      if (!response.ok) {
        throw new Error(
          `Request failed: ${response.status} - ${response.statusText}`,
        );
      }
      this.#triggerEvent(BOOKMARK_TOGGLE_EVENT, { action: "delete" });
      this.#snackbar?.show("Von der Merkliste entfernt.", "Rückgängig", () => {
        this.#addBookmark(true);
      });
      if (this.#bookmarkList) {
        // Update bookmarklist and buttons in teaserblock 'Empfohlene Artikel' on page 'Merkliste'
        await this.#bookmarkList.load();
        this.#bookmarkManager?.updateBookmarkButtons();
      }
    } catch (error) {
      // Reset on error
      this.active = true;
      this.#handleError(error);
    }
  }

  // Dispatches appropriate events
  #triggerEvent(event: string, options = {}) {
    this.dispatchEvent(
      new CustomEvent(event, {
        detail: {
          ...options,
          contentId: this.contentId,
          paidCategory: this.paidCategory,
        },
      }),
    );
  }

  // Handle bookmark API request errors
  #handleError(error: unknown) {
    let message: string;
    if (error instanceof Error) {
      message = error.message;
    } else {
      message = String(error);
    }
    console.error(message);
    this.#triggerEvent("error", { message });
    this.#snackbar?.show(
      "Verbindungsfehler. Bitte versuchen Sie es später noch einmal.",
      "",
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
    );
  }

  // Set an accessible name (visually hidden <span>) on button element depending on "active" attribute.
  #setButtonAccessibleNameAndIconClass(active: boolean) {
    const accessibleNameSpan =
      this.#bookmarkButton.querySelector(".u-visually-hidden");

    if (accessibleNameSpan) {
      accessibleNameSpan.textContent = active
        ? accessibleNameActive
        : accessibleNameInactive;
    }
    this.#bookmarkIcon.className = active ? iconClassActive : iconClassInactive;
  }

  attributeChangedCallback(name: string, _oldValue: string, newValue: string) {
    if (name === "active") {
      // update accessible name and icon class
      this.#setButtonAccessibleNameAndIconClass(newValue !== null);
    }
  }
}

"customElements" in window &&
  customElements.get("ws-bookmarkbutton") === undefined &&
  customElements.define("ws-bookmarkbutton", BookmarkButton);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-bookmarkbutton": BookmarkButton;
  }
}
