import type {
  LoaderConfig,
  MediaConfig,
  PlayerInterface,
} from "@foundation-player/loader";
import type { Player } from "./media_player.js";
import { Media } from "./media_player.types";

export abstract class Plugin {
  protected media: Media;

  public constructor(media: Media) {
    this.media = media;
  }

  /**
   * Add plugin related eventListener to player within this method
   */
  public addEventListener?(foundationPlayer: PlayerInterface): void;

  /**
   * Add plugin related loader configuration to Foundation player within this method
   */
  public addFoundationLoaderConfig?(
    dataset?: DOMStringMap,
  ):
    | LoaderConfig["pluginConfigs"]
    | Promise<LoaderConfig["pluginConfigs"] | undefined>
    | undefined;

  /**
   * Add plugin related media configuration to Foundation player within this method
   */
  public addFoundationMediaConfig?(
    player?: Player,
  ):
    | Partial<MediaConfig>
    | Promise<Partial<MediaConfig> | undefined>
    | undefined;

  /**
   * The implementation of this method decides whether the other public methods
   * of the plugin are executed
   */
  public abstract isEnabled(): boolean;
}
