export class CopyLink extends HTMLElement {
  connectedCallback() {
    /* Copy Link function */
    const getUrlButton = this.querySelector("button");
    if (getUrlButton) {
      getUrlButton.addEventListener("click", function () {
        const currentUrl = window.location.href;
        const getIcon = getUrlButton.querySelector("i");
        navigator.clipboard.writeText(currentUrl);

        /* ui animation */
        getUrlButton.classList.add("change-bg");

        setTimeout(function () {
          getUrlButton.classList.add("start-animation");
        }, 500);

        if (getIcon) {
          getIcon.classList.replace("icon-link", "icon-check-default");
        }

        setTimeout(function () {
          if (getIcon) {
            getIcon.classList.replace("icon-check-default", "icon-link");
            getUrlButton.classList.remove("change-bg", "start-animation");
          }
        }, 2000);
      });
    }
  }
}

customElements.get("ws-copylink") ??
  customElements.define("ws-copylink", CopyLink);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-copylink": CopyLink;
  }
}
