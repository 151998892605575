import type {
  LoaderConfig,
  MediaConfig,
  NielsenMediaPluginConfig,
  NielsenPluginConfig,
} from "@foundation-player/loader";
import { Plugin } from "./media_plugin.js";
import { Media } from "./media_player.types";

/**
 * For further information on the Nielsen plugin, please also refer
 * to the corresponding API documentation in the Foundation Player documentation:
 *
 * @see https://docs.player.foundation/v1/integration/available-plugins#nielsen
 * @see https://docs.player.foundation/v1/api/types/interfaces/nielsenpluginconfig/
 * @see https://docs.player.foundation/v1/api/types/interfaces/nielsenmediapluginconfig/
 */
export class PluginNielsen extends Plugin {
  public constructor(media: Media) {
    super(media);
  }

  public isEnabled(): boolean {
    return true;
  }

  public addFoundationLoaderConfig(): LoaderConfig["pluginConfigs"] {
    const nielsenPluginConfig: NielsenPluginConfig = {
      commentPath: this.media.ivwTopic,
    };
    return {
      "foundation.plugin.nielsen": nielsenPluginConfig,
    };
  }

  public addFoundationMediaConfig(): Partial<MediaConfig> {
    const nielsenMediaPluginConfig: NielsenMediaPluginConfig = {
      commentPath: this.media.ivwTopic,
      id: this.media.id,
    };

    return {
      plugins: {
        "foundation.plugin.nielsen": nielsenMediaPluginConfig,
      },
    };
  }
}
