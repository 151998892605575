import { VideoConfig } from "./media_video-config.js";
import { Player } from "./media_player.js";
import { PluginAdvertising } from "./media_plugin-advertising.js";
import { PluginAutopause } from "./media_plugin-autopause.js";
import { PluginGa4 } from "./media_plugin-ga4.js";
import { PluginHeartbeat } from "./media_plugin-heartbeat.js";
import { PluginNielsen } from "./media_plugin-nielsen.js";

export class VideoPlayer extends Player {
  protected readonly config: VideoConfig;

  public constructor(element: HTMLElement) {
    super(element);
    this.config = new VideoConfig(this.media);
  }

  protected registerPlugins(): void {
    this.plugins.add(new PluginAdvertising(this.media));
    this.plugins.add(new PluginAutopause(this.media));
    this.plugins.add(new PluginGa4(this.media, "video"));
    this.plugins.add(new PluginHeartbeat(this.media));
    this.plugins.add(new PluginNielsen(this.media));
  }
}
