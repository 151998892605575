import { PlayerConfig } from "./media_config.js";
import { Media } from "./media_player.types";

export class AudioConfig extends PlayerConfig {
  protected readonly uiMode: "audio" | "video" = "audio";

  public constructor(media: Media) {
    super(media);
    this.doAutoplay = media.isTextToSpeech;
  }
}
