import { PlayerConfig } from "./media_config.js";
import { Media } from "./media_player.types";

export class VideoConfig extends PlayerConfig {
  protected readonly uiMode: "audio" | "video" = "video";

  public constructor(media: Media) {
    super(media);
    this.doAutoplay = media.isLeadContent;
  }
}
