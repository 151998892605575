import type {
  HeartbeatMediaPluginConfig,
  HeartbeatPluginConfig,
  LoaderConfig,
  MediaConfig,
} from "@foundation-player/loader";
import { Plugin } from "./media_plugin.js";
import { Media } from "./media_player.types";

/**
 * For further information on the Heartbeat plugin, please also refer
 * to the corresponding API documentation in the Foundation Player documentation:
 *
 * @see https://docs.player.foundation/v1/integration/available-plugins/#heartbeat
 * @see https://docs.player.tvnow.de/interfaces/HeartbeatPluginConfig.html
 * @see https://docs.player.tvnow.de/interfaces/HeartbeatMediaPluginConfig.html
 */
export class PluginHeartbeat extends Plugin {
  public constructor(media: Media) {
    super(media);
  }

  public isEnabled(): boolean {
    return true;
  }

  public addFoundationLoaderConfig(): LoaderConfig["pluginConfigs"] {
    const hearbeatConfig: HeartbeatPluginConfig = {
      endpoint: {
        vod: "https://px1.vtrtl.de/vt/hb.do",
        live: "https://px1.vtrtl.de/vtl/hb.do",
        event: "https://px1.vtrtl.de/vtl/hb.do",
      },
      isKidsProfile: false,
      offer: "rtlpweb",
      userStatus: this.media.userNoad ? 5 : 3,
      payStatus: this.media.restricted ? 11 : 10,
      videoService: "vms",
    };
    return {
      "foundation.plugin.heartbeat": hearbeatConfig,
    };
  }

  public addFoundationMediaConfig(): Partial<MediaConfig> {
    const heartbeatConfig: HeartbeatMediaPluginConfig = {
      id: "1012674",
      ivw: "/developer/default",
      startType: 0,
    };

    return {
      plugins: {
        "foundation.plugin.heartbeat": heartbeatConfig,
      },
    };
  }
}
