import type { UpscoreConfig } from "@website/types";

/**
 * Tracking provider interface for Upscore - a tool for publishers to track and
 * analyse content usage
 * @see https://www.upscore.com/
 */
export class Upscore extends HTMLElement {
  get url(): string {
    return this.getAttribute("url") ?? "";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    try {
      const configScript = this.querySelector(
        `script[type="application/json"]`,
      );
      if (!configScript) {
        console.warn("Upscore configuration script not found.");
      }
      const config = JSON.parse(configScript?.textContent ?? "{}");
      this.#executeScripts(config);
    } catch (error) {
      if (error instanceof SyntaxError) {
        console.error("Error parsing Upscore configuration:", error);
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  }

  #executeScripts(config: UpscoreConfig) {
    // @ts-expect-error - third-party embed code
    // prettier-ignore
    // eslint-disable-next-line no-var
    (function(u,p,s,c,r){u[r]=u[r]||function(p){(u[r].q=u[r].q||[]).push(p)},u[r].ls=1*new Date();var a=p.createElement(s),m=p.getElementsByTagName(s)[0];a.async=1;a.src=c;m.parentNode.insertBefore(a,m)})(window,document,'script','//files.upscore.com/async/upScore.js','upScore');

    window.upScore(config);

    // A/B headline testing script
    const script = document.createElement("script");
    script.src = `https://hl.upscore.com/config/${this.url}.js`;
    script.async = true;
    this.shadowRoot!.appendChild(script);
  }
}

customElements.get("ws-upscore") ??
  customElements.define("ws-upscore", Upscore);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-upscore": Upscore;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    upScore: (config: UpscoreConfig) => void;
  }
}
