export class Dialog extends HTMLElement {
  connectedCallback() {
    const closeButton = this.querySelector(".dialog__close-button"),
      dialog = this.querySelector("dialog");

    if (closeButton && dialog) {
      closeButton.addEventListener("click", function () {
        dialog.close();
      });

      dialog.addEventListener("click", (event) => {
        if (event.target === dialog) {
          dialog.close();
        }
      });
    }
  }
}

customElements.get("ws-dialog") ?? customElements.define("ws-dialog", Dialog);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-dialog": Dialog;
  }
}
