import { getViewport } from "./utils";

const template = document.createElement("template");

template.innerHTML = `
    <style>
      :host {
        display: block;
      }
      :host([hidden]) {
        display: none;
      }
    </style>
    <slot></slot>
`;

const omittedAdIds: string[] = ["superbanner_1", "skyscraper_1", "wallpaper_1"];

const isVisible = (element: HTMLElement | null): boolean => {
  if (!element) return false;
  const style = window.getComputedStyle(element);
  if (
    style.display === "none" ||
    style.visibility === "hidden" ||
    style.opacity === "0" ||
    style.height === "0px" ||
    style.maxHeight === "0px"
  )
    return false;
  return element.parentElement ? isVisible(element.parentElement) : true;
};

const addOrUpdateStylesheetRule = (
  styleSheet: HTMLStyleElement | null,
  selector: string,
  rule: string,
): HTMLStyleElement => {
  if (!styleSheet) {
    styleSheet = document.createElement("style");
    document.head.appendChild(styleSheet);
  }

  const sheet = styleSheet.sheet as CSSStyleSheet;
  const rules = sheet.cssRules || sheet.rules;
  const index = Array.from(rules).findIndex(
    (rule) => (rule as CSSStyleRule).selectorText === selector,
  );

  if (index !== -1) {
    sheet.deleteRule(index);
  }
  sheet.insertRule(`${selector} { ${rule} }`, rules.length);

  return styleSheet;
};

export class AdTag extends HTMLElement {
  static observedAttributes = ["disabled"];

  #styleSheet: HTMLStyleElement | null = null;

  get disabled(): boolean {
    return this.hasAttribute("disabled");
  }

  get loaded(): boolean {
    return this.hasAttribute("loaded");
  }

  set loaded(value: boolean) {
    value ? this.setAttribute("loaded", "") : this.removeAttribute("loaded");
  }

  get media(): string[] {
    return this.getAttribute("media")?.split(",") ?? [];
  }

  // "none" (default) | "manual"
  get reloading(): string {
    return this.getAttribute("reloading") ?? "none";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name === "disabled" && oldValue === "" && newValue === null) {
      this.#load();
      this.loaded = true;
    }
  }

  connectedCallback() {
    if (this.media.length && !this.media.includes(getViewport())) {
      this.remove();
      return;
    }
    if (!Array.isArray(window.MoltenBundle?.cmd) || this.disabled) return;

    if (!this.loaded) {
      this.#load();
      this.loaded = true;

      window.MoltenBundle.cmd.push(() => {
        window.MoltenBundle.registerAdPositionLoadedHandler(
          (position: Record<string, unknown>) => {
            if (
              !omittedAdIds.includes(this.id) &&
              typeof position.containsAd === "function" &&
              position.containsAd() &&
              typeof position.getName === "function" &&
              position.getName() === this.id
            ) {
              let adLabelElement = this.querySelector(".ad-labels");

              if (!adLabelElement) {
                adLabelElement = document.createElement("div");
                const uniqueClassName = `ad-${this.id}`;
                adLabelElement.classList.add(
                  "ad-labels",
                  "typo-ad-label",
                  uniqueClassName,
                );
                adLabelElement.innerHTML = "Anzeige";
                this.insertAdjacentElement("afterbegin", adLabelElement);
              }

              const target = (position.ad as Record<string, unknown>)
                .container as HTMLElement;
              const iframes = target.querySelectorAll("iframe");
              const iframe = Array.from(iframes).find((iframe) =>
                isVisible(iframe as HTMLElement),
              );
              if (!iframe) return;
              const width = iframe.offsetWidth;
              const uniqueClassName = `ad-${this.id}`;
              this.#styleSheet = addOrUpdateStylesheetRule(
                this.#styleSheet,
                `.${uniqueClassName}`,
                `width: ${width}px;`,
              );
            }
          },
        );
      });
    } else {
      // Reload ad if it is set to "manual" and the `connectedCallback` is called again (e.g. after the adtag was moved inside the DOM)
      if (this.reloading === "manual") this.#reload();
    }
  }

  #load() {
    window.MoltenBundle.cmd.push(() => window.MoltenBundle.push(this.id));
  }

  #reload() {
    window.MoltenBundle.cmd.push(() =>
      window.MoltenBundle.getClient().reloadAd(this.id),
    );
  }
}

"customElements" in window &&
  customElements.get("ws-adtag") === undefined &&
  customElements.define("ws-adtag", AdTag);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-adtag": AdTag;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    MoltenBundle: any;
  }
}
