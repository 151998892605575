import { polyfillScrollendEvent, supportsResizeObserver } from "./utils.js";

const template = document.createElement("template");

template.innerHTML = `
  <style>
    :host {
      border: none !important;
      contain: content;
      display: block;
      position: relative;
    }

    :host([truncated]) {
      display: flex;
    }

    :host([truncated]) .clamp-container {
      max-width: calc(100% - 50px);
    }

    :host([expanded]) .clamp-container {
      padding-right: 12px;
    }

    :host .clamp-container {
      --mask-gradient: rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 48px,
        rgba(0, 0, 0, 1) calc(100% - 48px), rgba(0, 0, 0, 0) 100%;

      mask-image: linear-gradient(var(--mask-gradient)),
        linear-gradient(#000, #000);
      mask-size: calc(100% - 12px) 100%, 12px 100%;
      mask-repeat: no-repeat;
      mask-position: 0 0, 100% 0;
    }

    :host .clamp-container.is-at-start {
      --mask-gradient: rgba(0, 0, 0, 1) calc(100% - 48px), rgba(0, 0, 0, 0) 100%;
    }

    :host .clamp-container.is-at-end {
      --mask-gradient: rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 48px;
    }

    :host .clamp-container.is-at-start.is-at-end {
      --mask-gradient: #000, #000;
    }

    :host([hidden]) {
      display: none;
    }

    :host [name="content"] {
      display: block;
    }

    :host [name="caption"] {
      display: block;
      max-height: rem(23px);
    }

    :host #expand-button {
      color: var(--color-text-invert)
    }

    :host([expanded]) [name="caption"] {
      max-height: none;
    }

    :host([truncated]) [name="credits"] {
      display: none;
    }

    :host([expanded]) [name="credits"] {
      display: block;
    }

    :host :is([name="expand-button"], [name="collapse-button"]) {
      display: none;
    }

    :host([truncated]) [name="expand-button"] {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      width: 100px;
    }

    :host([expanded]) [name="collapse-button"] {
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
  </style>

  <slot name="collapse-button"></slot>
  <div class="clamp-container is-at-start is-at-end" part="container">
    <slot name="content"></slot>
    <slot name="caption"></slot>
    <slot name="credits"></slot>
  </div>
  <slot name="expand-button"></slot>

`;

export default class Clamp extends HTMLElement {
  #collapseButton: HTMLButtonElement;
  #expandButton: HTMLButtonElement;
  #slots: Record<string, HTMLSlotElement> = {};
  #clampContainer: HTMLElement;

  resizeObserver: any;

  get expanded() {
    return this.hasAttribute("expanded");
  }

  set expanded(value) {
    value
      ? this.setAttribute("expanded", "")
      : this.removeAttribute("expanded");
  }

  get truncated() {
    return this.hasAttribute("truncated");
  }

  set truncated(value) {
    value
      ? this.setAttribute("truncated", "")
      : this.removeAttribute("truncated");
  }

  constructor() {
    super();

    // Setup Shadow DOM
    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));

    // Setup HTML elements
    this.#slots.collapse = this.shadowRoot!.querySelector(
      '[name="collapse-button"]',
    ) as HTMLSlotElement;
    this.#collapseButton =
      this.#slots.collapse.assignedElements()[0]! as HTMLButtonElement;
    this.#slots.caption = this.shadowRoot!.querySelector(
      '[name="caption"]',
    ) as HTMLSlotElement;
    this.#slots.content = this.shadowRoot!.querySelector(
      '[name="content"]',
    ) as HTMLSlotElement;
    this.#slots.credits = this.shadowRoot!.querySelector(
      '[name="credits"]',
    ) as HTMLSlotElement;
    this.#slots.expand = this.shadowRoot!.querySelector(
      '[name="expand-button"]',
    ) as HTMLSlotElement;
    this.#expandButton =
      this.#slots.expand.assignedElements()[0]! as HTMLButtonElement;
    this.#clampContainer = this.shadowRoot!.querySelector(
      ".clamp-container",
    ) as HTMLElement;
  }

  connectedCallback() {
    this.updateTruncated();
    this.#slots.expand!.addEventListener("slotchange", () => {
      this.setAttributes();
      this.registerEvents();
    });
    this.setAttributes();
    this.registerEvents();

    if (supportsResizeObserver) {
      this.resizeObserver = new ResizeObserver(this.updateTruncated);
      this.resizeObserver.observe(this);
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          this.collapse();
        }
      });
    });

    observer.observe(this);

    // Polyfills "scrollend" event in safari
    if (!("onscrollend" in window))
      polyfillScrollendEvent(this.#clampContainer);
  }

  setAttributes() {
    if (this.#expandButton instanceof HTMLElement) {
      if (!this.#expandButton.ariaExpanded) {
        this.#expandButton.ariaExpanded = "false";
      }
      if (this.id && !this.#expandButton.hasAttribute("aria-controls")) {
        this.#expandButton.setAttribute("aria-controls", this.id);
      }
    }
  }

  registerEvents() {
    this.#slots.caption!.addEventListener("click", this.expand);
    this.#expandButton.addEventListener("click", this.expand);
    this.#collapseButton.addEventListener("click", this.collapse);
  }

  updateTruncated = () => {
    // Get text inserted in #caption slot
    const captionNode = this.#slots.caption?.assignedNodes()[0];

    if (captionNode instanceof HTMLElement) {
      // Create a temporary, invisible element to compare its length with the length of the element in the caption slot
      const invisibleElement = document.createElement("div");
      invisibleElement.style.visibility = "hidden";
      invisibleElement.style.position = "absolute";
      invisibleElement.style.width = "auto";
      invisibleElement.style.whiteSpace = "nowrap";
      invisibleElement.textContent = captionNode.textContent;
      document.body.appendChild(invisibleElement);

      // Use scrollWidth to determine if the content is truncated
      const isTruncated =
        invisibleElement.scrollWidth > captionNode.scrollWidth;

      // Remove temporary element and set truncated attribute
      document.body.removeChild(invisibleElement);
      this.truncated = isTruncated && !this.expanded;
    } else {
      // Handle cases where the captionNode is not an HTMLElement
      this.truncated =
        this.#slots.credits!.assignedNodes()?.length > 0 && !this.expanded;
    }
  };

  expand = () => {
    if (this.expanded || !this.truncated) {
      return;
    }

    this.expanded = true;
    this.truncated = false;
    if (this.#clampContainer.scrollHeight > this.#clampContainer.clientHeight) {
      this.updateClampContainer();
      this.#clampContainer.addEventListener(
        "scrollend",
        this.updateClampContainer,
      );
    }
    this.dispatchEvent(
      new CustomEvent("change", { detail: { expanded: true } }),
    );
  };

  collapse = () => {
    if (!this.expanded) {
      return;
    }
    this.expanded = false;
    this.truncated = true;
    this.#clampContainer.classList.add("is-at-start", "is-at-end");
    this.dispatchEvent(
      new CustomEvent("change", { detail: { expanded: false } }),
    );
  };

  toggle = () => {
    this.expanded ? this.collapse() : this.expand();
  };

  updateClampContainer = () => {
    if (this.#clampContainer.scrollTop > 5) {
      this.#clampContainer.classList.remove("is-at-start");
    } else {
      this.#clampContainer.classList.add("is-at-start");
    }
    if (
      this.#clampContainer.scrollTop + this.#clampContainer.clientHeight <
      this.#clampContainer.scrollHeight - 5
    ) {
      this.#clampContainer.classList.remove("is-at-end");
    } else {
      this.#clampContainer.classList.add("is-at-end");
    }
  };

  disconnectedCallback() {
    this.#slots.content!.removeEventListener("click", this.expand);
    this.#expandButton.removeEventListener("click", this.expand);
    this.#collapseButton.removeEventListener("click", this.collapse);

    this.resizeObserver && this.resizeObserver.disconnect();
  }
}

"customElements" in window &&
  customElements.get("ws-clamp") === undefined &&
  customElements.define("ws-clamp", Clamp);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-clamp": Clamp;
  }
}
