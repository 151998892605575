import type {
  LoaderConfig,
  MediaConfig,
  MediaPluginConfigs,
} from "@foundation-player/loader";
import { Plugin } from "./media_plugin.js";
import { Media } from "./media_player.types";

/**
 * For further information on the Google Analytics plugin, please also refer
 * to the corresponding API documentation in the Foundation Player documentation:
 *
 * @see https://docs.player.foundation/v1/integration/available-plugins#google-analytics
 * @see https://docs.player.tvnow.de/interfaces/GoogleAnalytics4PluginConfig.html
 * @see https://docs.player.tvnow.de/interfaces/GoogleAnalytics4MediaPluginConfig.html
 */
export class PluginGa4 extends Plugin {
  // see EventTimeUpdate.#interval
  readonly #interval: number = 10;
  readonly #playerType: "audio" | "video";

  public constructor(media: Media, playerType: "audio" | "video") {
    super(media);
    this.#playerType = playerType;
  }

  public isEnabled(): boolean {
    return true;
  }

  public addFoundationLoaderConfig(): LoaderConfig["pluginConfigs"] {
    return {
      "foundation.plugin.googleAnalytics4": {
        enabled: true,
        progressTrackingIntervalInSeconds: this.#interval,
      },
    };
  }

  public addFoundationMediaConfig(): Partial<MediaConfig> {
    const googleAnalytics4Config: MediaPluginConfigs["foundation.plugin.googleAnalytics4"] =
      {
        contentType: this.#playerType,
        firstHeadline: this.media.tracking?.staticTitle,
        headline: this.media.headline,
        id: this.media.id,
        // Since we have no real lastPublicationDate (or lastModified) for videos in feed response,
        // we use firstPublicationDate here.
        // This is the way it was also used in former Publishing Player.
        lastPublicationDate: this.media.tracking?.firstPublicationDate,
      };

    if (this.media.tracking?.technicalTags) {
      googleAnalytics4Config.tags = this.media.tracking.technicalTags;
    }

    if (this.media.tracking?.hiddenSources) {
      googleAnalytics4Config.editorialSources =
        this.media.tracking.hiddenSources.map((sources: string[]) =>
          sources.join(),
        );
    }

    return {
      plugins: {
        "foundation.plugin.googleAnalytics4": googleAnalytics4Config,
      },
    };
  }
}
