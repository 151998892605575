import type {
  Config,
  Media,
  Renditions,
  Scope,
  Tracking,
} from "./media_player.types.js";
import {
  Environment,
  FoundationError,
  MediaConfig,
  PlayerInterface,
} from "@foundation-player/loader";
import { createPlayerUI } from "@foundation-player/ui";
import type { Plugin } from "./media_plugin.js";
import { parseDuration } from "./utils";

/**
 * Player class initializes all video players on current page
 */
export abstract class Player {
  public foundationPlayer: PlayerInterface | undefined;
  public plugins: Set<Plugin> = new Set<Plugin>();
  public media: Media;
  protected readonly element: HTMLElement;

  /**
   * Player config depends on media type (audio, video) and is defined in appropriate
   * subclasses
   */
  protected abstract readonly config: Config;

  protected constructor(element: HTMLElement) {
    this.element = element;
    this.media = Player.#convertDatasetToMedia(element.dataset);
    this.registerPlugins();
  }

  static #convertDatasetToMedia(dataset: DOMStringMap): Media {
    return {
      disableAds: dataset.disableAds === "true",
      disableDownload: dataset.disableDownload === "true",
      durationInSeconds: dataset.length
        ? parseDuration(dataset.length!)
        : undefined,
      environment:
        (dataset.environment as Environment) ?? Environment.Production,
      freeAccessibleSeconds:
        dataset.freeAccessibleSeconds !== undefined
          ? Number(dataset.freeAccessibleSeconds)
          : undefined,
      hasDrm: dataset.hasDrm === "true",
      headline: dataset.headline ?? "",
      homadClientConfigUrl: dataset.homadClient,
      id: dataset.id ?? "",
      isAudioOnly: dataset.mode === "audio",
      isLeadContent: ["", "true"].includes(dataset.isLeadContent as string),
      isTextToSpeech: typeof dataset.isTextToSpeech !== "undefined",
      ivwTopic: dataset.ivwTopic,
      kicker: dataset.kicker,
      posterUrl: dataset.poster,
      renditions:
        dataset.renditions !== undefined
          ? (JSON.parse(dataset.renditions) as Renditions)
          : undefined,
      restricted: dataset.restricted === "true",
      scope: dataset.scope !== undefined ? (dataset.scope as Scope) : undefined,
      tracking:
        dataset.tracking !== undefined
          ? (JSON.parse(dataset.tracking) as Tracking)
          : undefined,
      zone: dataset.zone,
      userNoad: dataset.userNoad === "true",
      userPlus: dataset.userPlus === "true",
    };
  }

  public async init(): Promise<void> {
    try {
      this.foundationPlayer = await createPlayerUI(
        this.element,
        await this.config.getLoaderConfig(this.plugins),
        this.config.getUiConfig(),
      );

      // Execute addEventListener method of all plugins
      this.plugins.forEach((plugin: Plugin) => {
        if (plugin.addEventListener && plugin.isEnabled()) {
          plugin.addEventListener(this.foundationPlayer!);
        }
      });

      // Load media
      const mediaConfig: MediaConfig = await this.config.getMediaConfig(this);
      await this.foundationPlayer
        .loadMedia(mediaConfig)
        .catch(async (error: unknown) => {
          await this.foundationPlayer!.destroy(error as FoundationError);
        });
    } catch (error) {
      console.error("Foundation Player", "Error creating config:", error);
    }
  }

  /**
   * Register all plugins relevant for current player mode ("video", "audio").
   * There are no common plugins configured in parent class "Player" to have all plugins set
   * at one place.
   */
  //
  protected abstract registerPlugins(): void;
}
