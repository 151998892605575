import type { PlayerLoaderConfig, UIConfig } from "@foundation-player/ui";
import {
  EncryptionType,
  LicenseServer,
  type LoaderConfig,
  LogLevel,
  type Manifest,
  type ManifestType,
  type MediaConfig,
  Platform,
  SourceType,
} from "@foundation-player/loader";
import type { Config, Media, Rendition } from "./media_player.types.js";
import type { Plugin } from "./media_plugin.js";
import type { Player } from "./media_player.js";
import { PluginConfig } from "@foundation-player/plugin-advertising";

function mergeObjects<TObject extends object, TSource extends object>(
  target: TObject,
  source: TSource,
): TObject & TSource {
  if (!isObject(target) || !isObject(source)) {
    return source as TObject & TSource;
  }

  Object.keys(source).forEach((key) => {
    const targetValue = (target as any)[key];
    const sourceValue = (source as any)[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      // Merge arrays by concatenation
      (target as any)[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      // Recursively merge nested objects
      (target as any)[key] = mergeObjects(targetValue, sourceValue);
    } else {
      // Direct assignment for other types
      (target as any)[key] = sourceValue;
    }
  });

  return target as TObject & TSource;
}

function isObject(value: any): value is object {
  return (
    value &&
    typeof value === "object" &&
    !Array.isArray(value) &&
    !(value instanceof Date)
  );
}

/**
 * Base class of configurations for Publishing Player. Must be extended by
 * classes for video and audio source.
 */
export abstract class PlayerConfig implements Config {
  protected doAutoplay = false;
  protected media: Media;

  readonly #licenseServerAddress: Record<
    "fairplay" | "playready" | "widevine",
    string
  > = {
    fairplay: "https://fairplay.rtl.de/Fairplay/license",
    playready: "https://playready-core.rtl.de/playready/api/license.asmx",
    widevine: "https://widevine.rtl.de/index/license",
  };

  protected abstract uiMode: "audio" | "video";

  protected constructor(media: Media) {
    this.media = media;
  }

  public async getLoaderConfig(
    plugins: Set<Plugin>,
  ): Promise<PlayerLoaderConfig> {
    const loaderConfig: PlayerLoaderConfig = {
      client: "rtldigitalnews",
      consentString: await this.#getConsent(),
      environment: this.media.environment,
      platform: Platform.Web,
      playerConfig: {
        autoplay: false,
        loggerConfig: {
          "*": LogLevel.Error,
        },
      },
      pluginConfigs: {},
      scope: this.media.scope,
    };

    // Merge loaderConfig from plugins
    await Promise.all(
      [...plugins].map(async (plugin: Plugin) => {
        if (plugin.addFoundationLoaderConfig && plugin.isEnabled()) {
          mergeObjects(
            loaderConfig.pluginConfigs as Record<string, PluginConfig>,
            (await plugin.addFoundationLoaderConfig()) as LoaderConfig,
          );
        }
      }),
    );
    console.info("Foundation Player", "LoaderConfig:", loaderConfig);

    return loaderConfig;
  }

  async #getConsent(): Promise<string> {
    const sourcepoint = document.querySelector(
      "ws-sourcepoint",
    ) as HTMLElement | null;

    if (sourcepoint) {
      await window.customElements.whenDefined("ws-sourcepoint");
      // Adjust the type as needed
      return await (sourcepoint as any).getTcString();
    } else {
      return ""; // Fallback if sourcepoint is not present
    }
  }

  public getUiConfig(): UIConfig {
    const uiConfig: UIConfig = {
      createPlayerOnDemand: !this.doAutoplay,
      durationInSeconds: this.media.durationInSeconds,
      headline: this.media.headline,
      mode: this.uiMode,
      poster: this.media.posterUrl,
      secondaryHeadline: this.media.kicker,
      theme: "dark",
    };
    console.info("Foundation Player", "UiConfig:", uiConfig);

    return uiConfig;
  }

  public async getMediaConfig(player: Player): Promise<MediaConfig> {
    const mediaConfig: MediaConfig = {
      // Property "autoplay" must always be true here.
      // Non-autoplay is handled by property "createPlayerOnDemand" in getUiConfig()
      // @see https://docs.player.tvnow.de/interfaces/UIConfig.html#createPlayerOnDemand
      autoplay: true,
      id: this.media.id,
      manifests: this.createManifestsFromData(),
      metadata: {
        headline: this.media.headline,
        userStarted: !this.doAutoplay,
      },
      type: this.media.isAudioOnly ? SourceType.Podcast : SourceType.VOD,
    };

    // These properties are necessary for smartphones native player in lockscreen.
    mediaConfig.metadata = mediaConfig.metadata ?? {};
    if (this.media.kicker !== undefined) {
      mediaConfig.metadata.secondaryHeadline = this.media.kicker;
    }
    if (this.media.posterUrl !== undefined) {
      mediaConfig.metadata.poster = this.media.posterUrl;
    }

    // Merge mediaConfig from plugins into basic mediaConfig
    await Promise.all(
      [...player.plugins].map(async (plugin: Plugin) => {
        if (plugin.addFoundationMediaConfig && plugin.isEnabled()) {
          mergeObjects(
            mediaConfig,
            (await plugin.addFoundationMediaConfig(
              player,
            )) as Promise<MediaConfig>,
          );
        }
      }),
    );
    console.info("Foundation Player", "MediaConfig:", mediaConfig);

    return mediaConfig;
  }

  /**
   * Create manifests for Publishing Player from manifests in data-renditions attribute
   */
  protected createManifestsFromData(): Manifest[] {
    const manifests: Manifest[] = [];
    for (const type in this.media.renditions) {
      if (!Object.hasOwn(this.media.renditions, type)) {
        continue;
      }
      let manifestType: ManifestType | null = null;
      switch (type) {
        case "hls":
          manifestType = "hlsfairplayhd";
          break;
        case "dash":
          manifestType = "dashhd";
          break;
        case "progressive":
          manifestType = "progressive";
          break;
      }

      if (manifestType === null) {
        continue;
      }

      const manifestSources: Manifest["sources"] = [];
      manifestSources.push({
        priority: "main",
        // @ts-expect-error TS7053: Element implicitly has an any type because expression of type string can't be used
        // to index type Renditions
        url: (this.media.renditions[type] as Rendition).url,
      });
      const foundationManifest: Manifest = {
        sources: manifestSources,
        type: manifestType,
      };
      // License server do not respond on localhost because of CORS restrictions.
      // Testing media with drm can only be tested in review app or changing hosts file.
      if (this.media.hasDrm) {
        foundationManifest.licenseServers = this.#getLicenseServers();
      }
      manifests.push(foundationManifest);
    }

    return manifests;
  }

  #getLicenseServers(): LicenseServer[] {
    return [
      {
        address: this.#licenseServerAddress.fairplay,
        certificate: "https://fairplay.rtl.de/Certificate/RTLiDe_cert.cer",
        encryptionType: EncryptionType.FairPlay,
      },
      {
        address: this.#licenseServerAddress.playready,
        encryptionType: EncryptionType.PlayReady,
      },
      {
        address: this.#licenseServerAddress.widevine,
        encryptionType: EncryptionType.Widevine,
      },
    ];
  }
}
