import type { LoaderConfig } from "@foundation-player/loader";
import { Plugin } from "./media_plugin.js";

/**
 * For further information on the visibility plugin, please also refer
 * to the corresponding API documentation in the Foundation Player documentation:
 *
 * @see https://docs.player.foundation/v1/integration/available-plugins#visibility
 * @see https://docs.player.tvnow.de/interfaces/VisibilityPluginConfig.html
 */
export class PluginAutopause extends Plugin {
  public isEnabled(): boolean {
    return true;
  }

  public addFoundationLoaderConfig(): LoaderConfig["pluginConfigs"] {
    return {
      "foundation.plugin.visibility": {
        pauseAds: true,
        pauseContent: true,
      },
    };
  }
}
