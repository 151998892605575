import { Sourcepoint } from "./sourcepoint";

const template = document.createElement("template");

export const NEWSLETTER_TOPIC_CHANGE = "change";
export const NEWSLETTER_TOPIC_CLICKED = "nl_clicked";
export const NEWSLETTER_SUBSCRIPTION = "nl_confirmed";

export type NewsletterClickedEvent = CustomEvent<{
  nl_id: string;
}>;
export type NewsletterSubscriptionEvent = CustomEvent<{
  nl_id: string[];
}>;

template.innerHTML = `
  <style>

    :host {
      display: block;
    }

    :host #content[hidden] {
      /* Wenn die "display" Eigenschaft überschrieben wird, muss "display: none"
        wiedergestellt werden, für den Fall das dass Element ein "hidden" Attribut hat. */
      display: none;
    }

  </style>
  <slot name="content" id="content"></slot>
  <slot name="consentHeadline" id="consentHeadline"></slot>
  <slot name="consentText" id="consentText"></slot>
  <slot name="button"></slot>
`;

export class SocialWidget extends HTMLElement {
  #slots: Record<string, HTMLSlotElement> = {};
  #content: Element;
  #button: HTMLButtonElement;
  #isLoaded = false;

  get noTracking() {
    return this.hasAttribute("data-no-tracking");
  }

  constructor() {
    super();

    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));

    this.#slots.button = this.shadowRoot!.querySelector(
      `slot[name="button"]`,
    )! as HTMLSlotElement;
    this.#slots.content = this.shadowRoot!.querySelector(
      "#content",
    )! as HTMLSlotElement;

    this.#button =
      this.#slots.button.assignedElements()[0]! as HTMLButtonElement;
    this.#content = this.#slots.content.assignedElements()[0] as Element;
  }

  async connectedCallback() {
    const consent = await this.#getConsent();

    if (this.noTracking) {
      this.#button!.addEventListener("click", () => this.embedWidget());
      return;
    } else if (consent) {
      this.embedWidget();
    } else {
      this.#button!.addEventListener("click", () => this.embedWidget());
    }

    const checkForTcfApiEvents = () => {
      if (typeof window.__tcfapi === "function") {
        const sourcepoint = document.querySelector(
          "ws-sourcepoint",
        ) as Sourcepoint | null;

        sourcepoint?.tcfApiEventlistener(
          this.updateConsentForWidget.bind(this),
          this.updateConsentForWidget.bind(this),
        );

        clearInterval(intervalId); // Stop checking for the function
      }
    };

    const intervalId = setInterval(checkForTcfApiEvents, 5); // Check every 5 milliseconds for the function
  }

  async updateConsentForWidget() {
    if (this.#isLoaded) {
      return;
    }
    const consent = await this.#getConsent();
    if (consent) {
      this.embedWidget();
    }
  }

  async #getConsent(): Promise<boolean> {
    const sourcepoint = document.querySelector(
      "ws-sourcepoint",
    ) as Sourcepoint | null;

    if (sourcepoint) {
      // Ensure the 'ws-sourcepoint' element is defined
      await window.customElements.whenDefined("ws-sourcepoint");

      // We have different ids on different environments (stern is used as an example, but this is true for all brands):
      //  662779476b5999067b9e054b => next.dev. and *.stern.de
      //  5ffec086984dc02862cbbed1 => *.stern.de and Apps
      //  63d7a897cc43ae06156ab273 => localhost
      //  63d7a65d86308407127a3350 => dev.geo.de (this one is the only one that is specific to geo)

      return sourcepoint.getPurposeIdsConsent([
        "63d7a897cc43ae06156ab273",
        "662779476b5999067b9e054b",
        "5ffec086984dc02862cbbed1",
        "63d7a65d86308407127a3350",
      ]);
    } else {
      // Fallback if sourcepoint is not present
      return false;
    }
  }

  embedWidget = () => {
    // Create new element from content slot
    const documentFragment = document
      .createRange()
      .createContextualFragment(
        `<div class="widget-element">${this.#content.textContent || ""}</div>`,
      ); // https://davidwalsh.name/convert-html-stings-dom-nodes
    this.replaceWith(documentFragment);

    // Remove ws-socialwidget
    this.remove();
    this.#isLoaded = true;
  };

  disconnectedCallback() {
    this.#button.removeEventListener("click", () => this.embedWidget());
  }
}

"customElements" in window &&
  customElements.get("ws-socialwidget") === undefined &&
  customElements.define("ws-socialwidget", SocialWidget);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-socialwidget": SocialWidget;
  }
}
