const template = document.createElement("template");

template.innerHTML = `
  <style>
    :host {
      text-decoration: var(--ws-link-text-decoration);
    }

    :host(:hover),
    :host(:focus) {
      cursor: pointer;
    }
  </style>

  <slot></slot>
`;

export class Link extends HTMLElement {
  get href() {
    return this.getAttribute("href") ?? "";
  }

  set href(value) {
    this.setAttribute("href", value.toString());
  }

  set role(value: string) {
    this.setAttribute("role", value.toString());
  }

  set tabindex(value: string) {
    this.setAttribute("tabindex", value.toString());
  }

  get target() {
    return this.getAttribute("target") ?? "_self";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));
  }

  connectedCallback() {
    if (this.href?.includes("wdycf=%s"))
      this.href = this.#replaceWdycfParam(this.href);
    if (!this.hasAttribute("role")) this.role = "link";
    if (!this.hasAttribute("tabindex")) this.tabindex = "0";
    this.addEventListener("click", this.#handleClick);
    this.addEventListener("keydown", this.#handleKeydown);
  }

  #handleClick = (event: MouseEvent) => {
    this.#openLink();
    event.preventDefault();
    event.stopPropagation();
  };

  #handleKeydown = (event: KeyboardEvent) => {
    if (event.key !== "Enter") return;
    this.#openLink();
    event.preventDefault();
    event.stopPropagation();
  };

  #openLink() {
    if (!this.href) return;
    window.open(this.href, this.target);
  }

  #replaceWdycfParam(url: string): string {
    const wdycf = new URL(window.location.toString());
    wdycf.searchParams.set("cc_bust", Date.now().toString());
    return url.replace(
      "wdycf=%s",
      `wdycf=${encodeURIComponent(wdycf.toString())}`,
    );
  }

  disconnectedCallback() {
    this.removeEventListener("click", this.#handleClick);
    this.removeEventListener("keydown", this.#handleKeydown);
  }
}

"customElements" in window &&
  customElements.get("ws-link") === undefined &&
  customElements.define("ws-link", Link);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-link": Link;
  }
}
