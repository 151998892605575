import type { AnalyticsConfig } from "@website/types";
import { getOrientation, getViewport } from "./utils";
import { ChangeEvent, GALLERY_CHANGE_EVENT } from "./gallery";
import { netidEventsArray, StatusEvent } from "./netid";
import { LOADED_EVENT } from "./bookmarklist";
import { BOOKMARK_TOGGLE_EVENT, BookmarkButton } from "./bookmarkbutton";
import { MESSAGE_HANDLER_INTERACTION_EVENT } from "./messagehandler";
import { Link } from "./link";
import { Paywall, ZEPHR_DECISIONS_FINISHED_EVENT } from "./paywall";
import { TTS_CLOSED_EVENT, TTS_OPENED_EVENT } from "./texttospeech";

export const GTM_INIT_EVENT = "gtmInitialized";

export type NewsletterClickedEvent = CustomEvent<{
  nl_id: string;
}>;
export type NewsletterSubscriptionEvent = CustomEvent<{
  nl_id: string | string[];
}>;
export const NEWSLETTER_TOPIC_CHANGE = "change";
export const NEWSLETTER_TOPIC_CLICKED = "nl_clicked";
export const NEWSLETTER_SUBSCRIPTION = "nl_confirmed";

type EventData = {
  event: string;
  [key: string]: unknown;
};

const wsPaywallReady = window.customElements.whenDefined("ws-paywall");
const wsLinkReady = window.customElements.whenDefined("ws-link");

/**
 * This web component, GoogleTagManager, is a custom HTML element designed
 * to integrate Google Tag Manager (GTM) into a web page.
 * @see  https://support.google.com/tagmanager/answer/14847097
 */
export class GoogleTagManager extends HTMLElement {
  get containerId(): string {
    return this.getAttribute("container-id") ?? "";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    try {
      this.#initialize(this.#defaultPageConfig());

      //Add eventlisteners for virtual pageview in galleries
      const galleries = document.querySelectorAll("ws-gallery");
      galleries.forEach((gallery) => {
        gallery.addEventListener(
          GALLERY_CHANGE_EVENT,
          this.#handleVirtualPageview,
        );
      });

      //Add eventlisteners for virtual pageview in messagehandler
      const messagehandlers = document.querySelectorAll("ws-messagehandler");
      messagehandlers.forEach((messagehandler) => {
        messagehandler.addEventListener(
          MESSAGE_HANDLER_INTERACTION_EVENT,
          this.#handleVirtualPageviewMessagehandler,
        );
      });

      //Add eventlisteners for TTS
      const ttsHandlers = document.querySelectorAll("ws-texttospeech");
      ttsHandlers.forEach((ttsHandler) => {
        ttsHandler.addEventListener(TTS_OPENED_EVENT, this.#handleTtsOpened);
        ttsHandler.addEventListener(TTS_CLOSED_EVENT, this.#handleTtsClosed);
      });

      //Add eventlistener for NetID
      const netid = document.querySelector("ws-netid");
      netidEventsArray.forEach((entry) => {
        netid?.addEventListener(entry, this.#handleNetidEvent);
      });

      //Add eventlisteners for Newsletterform
      const newsletterForms: NodeListOf<HTMLFormElement> =
        document.querySelectorAll(".js-nl-form-tracking");

      if (newsletterForms) {
        newsletterForms.forEach((form) => {
          const newsletterTopicsList: string[] = [];

          const newsletterTopics = form?.querySelectorAll(
            "[id*=newsletter_id]",
          ) as unknown as HTMLInputElement[];

          let singleNewsletter: HTMLInputElement;
          if (newsletterTopics && newsletterTopics.length > 0) {
            newsletterTopics?.forEach((topic) => {
              this.#handleNewsletterTopicEventlistener(
                topic,
                newsletterTopicsList,
              );
            });
          } else {
            // for single newsletter, newsletter-Id is placed somewhere else
            const singleNewsletterList = form?.querySelectorAll(
              'input[type="hidden"][name="gid"]',
            ) as unknown as HTMLInputElement[];
            singleNewsletter =
              singleNewsletterList[0] || new HTMLInputElement();
            // shape HTML element so it matches the multi-newsletter "topic"
            singleNewsletter.id = singleNewsletter.value || "";
            singleNewsletter.setAttribute("checked", "true");
            // call as usual
            this.#handleNewsletterTopicEventlistener(singleNewsletter, []);
          }

          const submitButton = form.querySelector(
            'button[type="submit"]',
          ) as HTMLButtonElement;

          if (submitButton) {
            submitButton!.onclick = () => {
              let nl_id: string | string[] = newsletterTopicsList;
              // length is 0 when we deal with a single newsletter
              if (newsletterTopicsList.length === 0) {
                nl_id = singleNewsletter.getAttribute("id") || "";
              }
              // use array only when 2 or more newsletters checked
              if (nl_id.length === 1) nl_id = nl_id[0] as string;
              const event: NewsletterSubscriptionEvent = new CustomEvent(
                NEWSLETTER_SUBSCRIPTION,
                {
                  detail: {
                    nl_id,
                  },
                },
              );
              this.#handleNewsletterSubscribed(event);
            };
          }
        });
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        console.error("Error parsing Google Tag Manager configuration:", error);
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
    this.#bindTeasers();
    this.#bindBookmarkButtons();
    this.#bindPaywall();
  }

  #executeScripts() {
    const script = document.createElement("script");
    script.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${this.containerId}');`;
    this.shadowRoot!.appendChild(script);
  }

  #handleNetidEvent = (e: Event) => {
    const event = e as StatusEvent;
    const details = event.detail
      ? { [event.detail.eventAction]: event.detail.eventLabel }
      : null;
    window.dataLayer.push({
      event: event.type,
      ...details,
    });
  };

  #handleNewsletterTopicEventlistener(
    topic: HTMLInputElement,
    topicList: string[],
  ) {
    topic.addEventListener(NEWSLETTER_TOPIC_CHANGE, () => {
      const pattern = /\d+/;
      const topicId = topic?.id?.match(pattern)![0];
      if (topic.checked) {
        const event: NewsletterClickedEvent = new CustomEvent(
          NEWSLETTER_TOPIC_CLICKED,
          {
            detail: {
              nl_id: topicId,
            },
          },
        );
        this.#handleNewsletterTopicClicked(event);
        topicList.push(topicId);
      } else {
        const topicIndex = topicList.indexOf(topicId);
        topicList.splice(topicIndex, 1);
      }
    });
  }

  #handleNewsletterTopicClicked = (e: Event) => {
    const event = e as NewsletterClickedEvent;
    this.#pushToDataLayer({
      event: event.type,
      ...event.detail,
    });
  };

  #handleNewsletterSubscribed = (e: Event) => {
    const event = e as NewsletterSubscriptionEvent;
    this.#pushToDataLayer({
      event: event.type,
      ...event.detail,
    });
  };

  #handleTtsOpened = () => {
    const currentConfig = this.#defaultPageConfig();
    const ttsConfig = {
      ...currentConfig,
      event: "tts_opened",
    };
    window.dataLayer.push(ttsConfig);
  };

  #handleTtsClosed = () => {
    const currentConfig = this.#defaultPageConfig();
    const ttsConfig = {
      ...currentConfig,
      event: "tts_closed",
    };
    window.dataLayer.push(ttsConfig);
  };

  #handleVirtualPageview = (e: Event) => {
    const event = e as ChangeEvent;
    const { galleryHeadline, itemNumber, itemUrl, totalItems } = event.detail;

    const currentConfig = this.#defaultPageConfig();
    const galleryConfig = {
      ...currentConfig,
      content: {
        ...currentConfig.content,
        virt_path_url: itemUrl,
      },
      gallery: {
        current_picture: itemNumber,
        galleryHeadline: galleryHeadline,
        number_of_pictures: totalItems,
      },
      event: "virt_path",
    };
    window.dataLayer.push(galleryConfig);
  };

  #handleVirtualPageviewMessagehandler = () => {
    const currentConfig = this.#defaultPageConfig();
    const messagehandlerConfig = {
      ...currentConfig,
      event: "virt_path",
    };
    window.dataLayer.push(messagehandlerConfig);
  };

  async #initialize(config: AnalyticsConfig) {
    // Enhance the configuration with clientside data
    config.content.molten_bundle_site_type = getViewport();
    config.tech.device_orientation = getOrientation();

    // Enhance the configuration with user data
    const user = document.querySelector("ws-user");
    if (user) {
      await window.customElements.whenDefined("ws-user");
      const status = await user.getStatus();
      config.login.distribution_channel = status.type ?? "not_set";
      config.login.hashed_user_id = status.hashedUserId ?? "not_set";
      config.login.product_user_status = status.trackingStatus ?? "not_set";
      config.login.registration_status = status.registrationStatus ?? "not_set";
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(config);

    this.#executeScripts();
    this.dispatchEvent(new CustomEvent(GTM_INIT_EVENT));
  }

  #defaultPageConfig(): AnalyticsConfig {
    const configScript = this.querySelector(`script[type="application/json"]`);

    if (!configScript) {
      console.warn("Google Tag Manager configuration script not found.");
      return this.#defaultConfig(); // Return a basic default config
    }

    try {
      const config = JSON.parse(configScript.textContent ?? "{}");
      return this.#validateConfig(config) ? config : this.#defaultConfig();
    } catch (error) {
      console.error("Error parsing Google Tag Manager configuration:", error);
      return this.#defaultConfig(); // Fallback to default config
    }
  }

  // Define a basic fallback configuration
  #defaultConfig() {
    return {
      content: {},
      tech: {},
      login: {},
    } as AnalyticsConfig;
  }

  // Validation method for required structure or fields
  #validateConfig(config: AnalyticsConfig): boolean {
    // Simple check for example purposes; expand as needed
    return (
      config &&
      typeof config === "object" &&
      "content" in config &&
      "tech" in config
    );
  }

  #bindTeasers() {
    // Select all elements that match the attribute "data-trace-id='teaser'"
    const teasers = document.querySelectorAll<HTMLElement>(
      "[data-trace-id='teaser']",
    );

    teasers.forEach((teaser) => {
      // Extract dataset attributes with default empty strings for safety
      const {
        brandIdentifier = "",
        contentId = "",
        headline = "",
        paidCategory = "",
        teaserType = "",
        type = "",
      } = teaser?.dataset as DOMStringMap;
      const teaserLink = teaser.querySelector("a");

      if (!teaserLink) return;

      // Add click event listener for each teaser
      teaserLink.addEventListener("click", () => {
        // Push the teaser click event to the data layer
        this.#pushToDataLayer({
          event: "teaser_clicked",
          content: {
            brand: brandIdentifier,
            headline,
            id: contentId,
            paidCategory,
            teaserType,
            type,
          },
        });
      });
    });
  }

  #bindBookmarkButtons() {
    const bookmarkPaywallTriggers = document.querySelectorAll<HTMLElement>(
      "ws-paywallcontrol .feature-bar__button:has(.icon-bookmark)",
    );
    bookmarkPaywallTriggers.forEach((trigger) => {
      trigger.addEventListener("click", this.#handleForbiddenBookmarkAddEvents);
    });

    const bindSingleButton = (button: BookmarkButton) => {
      button.addEventListener(
        BOOKMARK_TOGGLE_EVENT,
        this.#handleBookmarkButtonEvents,
      );
    };

    const bookmarkButtons = document.querySelectorAll("ws-bookmarkbutton");
    bookmarkButtons.forEach(bindSingleButton);

    const bookmarkList = document.querySelector("ws-bookmarklist");
    if (bookmarkList) {
      // Wait for the bookmark list to be loaded. If the event was fired before
      // the bookmarks must have been included in bookmarkButtons.
      // @ts-expect-error TS2769: No overload matches this call.
      bookmarkList.addEventListener(
        LOADED_EVENT,
        (event: CustomEvent): void => {
          const newBookmarkButtons = (
            event.target as HTMLElement
          ).querySelectorAll("ws-bookmarkbutton");
          newBookmarkButtons.forEach(bindSingleButton);
        },
      );
    }
  }

  #handleForbiddenBookmarkAddEvents = (event: Event) => {
    const bookmarkPaywallTrigger = (event.target as HTMLElement).closest(
      "ws-paywallcontrol button:has(.icon-bookmark)",
    );
    if (bookmarkPaywallTrigger) {
      this.#pushToDataLayer({
        event: "bookmark_clicked",
        bookmark_state: "forbidden",
      });
    }
  };

  #handleBookmarkButtonEvents = (event: Event) => {
    const bookmarkbutton = (event.target as HTMLElement).closest(
      "ws-bookmarkbutton",
    );
    if (bookmarkbutton) {
      this.#pushToDataLayer({
        event: "bookmark_clicked",
        bookmark_state: bookmarkbutton.active ? "added" : "removed",
      });
    }
  };

  #paywallClickedListener = () => {
    const paywalls = document.querySelectorAll("ws-paywall");

    paywalls.forEach((paywall) => {
      const link = paywall.querySelector("a, ws-link");
      if (!link) return;

      const href =
        link.tagName.toLowerCase() === "a"
          ? link.getAttribute("href")
          : (link as Link).href;

      const offerId = href
        ? (() => {
            const url = new URL(href, document.location.origin);
            return url.pathname.includes("checkout/")
              ? url.pathname.split("checkout/")[1]
              : "not_set";
          })()
        : "not_set";

      link.addEventListener("click", () => {
        const p = paywall as Paywall;
        window.dataLayer.push({
          event: "paywall_clicked",
          content: {
            id: p.contentId ?? "not_set",
            headline: p.headline ?? "not_set",
            type: p.trackingType ?? "not_set",
            sub_type: p.subtype ?? "not_set",
            overlay: p.isDialog,
          },
          product: {
            offer_id: offerId,
          },
        });
      });
    });
  };

  #paywallLoginClickedListener = () => {
    const paywalls = document.querySelectorAll("ws-paywall");

    paywalls.forEach((paywall) => {
      const link = [...paywall.querySelectorAll("a, ws-link")].find((elm) => {
        const linkUrl = new URL(
          elm.getAttribute("href")!,
          document.location.origin,
        );
        return linkUrl!.pathname.startsWith("/p-user/login");
      });

      if (link) {
        link.addEventListener("click", () => {
          const p = paywall as Paywall;
          window.dataLayer.push({
            event: "paywall_login_clicked",
            content: {
              id: p.contentId ?? "not_set",
              headline: p.headline ?? "not_set",
              type: p.trackingType ?? "not_set",
              sub_type: p.subtype ?? "not_set",
              overlay: p.isDialog,
            },
          });
        });
      }
    });
  };

  async #handlePaywallShown() {
    await wsPaywallReady;

    const paywalls = document.querySelectorAll("ws-paywall");
    await Promise.all(
      [...paywalls].map(async (paywall) => {
        const dialog = paywall.closest("dialog");
        if (!dialog) return;

        const callback = (mutationsList: MutationRecord[]) => {
          mutationsList.forEach((mutation) => {
            if (
              mutation.type === "attributes" &&
              mutation.attributeName === "open"
            ) {
              if (dialog.hasAttribute("open")) {
                const p = paywall as Paywall;
                window.dataLayer.push({
                  event: "paywall_shown",
                  content: {
                    id: p.contentId ?? "not_set",
                    headline: p.headline ?? "not_set",
                    type: p.trackingType ?? "not_set",
                    sub_type: p.subtype ?? "not_set",
                    overlay: p.isDialog,
                  },
                });
              }
            }
          });
        };

        const observer = new MutationObserver(callback);

        observer.observe(dialog, { attributes: true });
      }),
    );
  }

  async #bindPaywall() {
    await Promise.all([wsPaywallReady, wsLinkReady]);
    document.addEventListener(
      ZEPHR_DECISIONS_FINISHED_EVENT,
      this.#paywallClickedListener,
    );
    document.addEventListener(
      ZEPHR_DECISIONS_FINISHED_EVENT,
      this.#paywallLoginClickedListener,
    );
    document.addEventListener(
      ZEPHR_DECISIONS_FINISHED_EVENT,
      this.#handleInlinePaywallVisibility,
    );
    this.#handlePaywallShown();
  }

  // Private method to push data to the data layer
  #pushToDataLayer(data: EventData): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }

  #handleInlinePaywallVisibility() {
    const paywall = [...document.querySelectorAll("ws-paywall")].find(
      (element) => !element.isDialog,
    );

    if (!paywall) {
      return;
    }

    function isElementAtLeast50PercentVisible(element: HTMLElement): boolean {
      const rect = element.getBoundingClientRect();
      const elementHeight = rect.height;

      if (elementHeight === 0) {
        return false;
      }

      const visibleHeight =
        Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);

      return visibleHeight / elementHeight >= 0.5;
    }

    const observeElementVisibility = (
      element: HTMLElement,
      callback: (isVisible: boolean) => void,
    ) => {
      if (element.dataset.observerAttached === "true") {
        return () => {
          /* no-op */
        };
      }

      element.dataset.observerAttached = "true";

      const checkVisibility = () => {
        const isVisible = isElementAtLeast50PercentVisible(element);
        callback(isVisible);
      };

      checkVisibility();

      const observer = new IntersectionObserver(
        ([entry]) => {
          callback(entry!.intersectionRatio >= 0.5);
        },
        {
          threshold: [0.5],
        },
      );

      observer.observe(element);

      return () => {
        observer.unobserve(element);
        delete element.dataset.observerAttached;
      };
    };

    observeElementVisibility(paywall, (isVisible) => {
      if (isVisible) {
        window.dataLayer.push({
          event: "paywall_shown",
          content: {
            id: paywall.contentId ?? "not_set",
            headline: paywall.headline ?? "not_set",
            type: paywall.trackingType ?? "not_set",
            sub_type: paywall.subtype ?? "not_set",
            overlay: false,
          },
        });
      }
    });
  }

  disconnectedCallback() {
    const galleries = document.querySelectorAll("ws-gallery");
    galleries.forEach((gallery) => {
      gallery.removeEventListener(
        GALLERY_CHANGE_EVENT,
        this.#handleVirtualPageview,
      );
    });

    const messagehandlers = document.querySelectorAll("ws-messagehandler");
    messagehandlers.forEach((messagehandler) => {
      messagehandler.removeEventListener(
        MESSAGE_HANDLER_INTERACTION_EVENT,
        this.#handleVirtualPageviewMessagehandler,
      );
    });

    const ttsHandlers = document.querySelectorAll("ws-texttospeech");
    ttsHandlers.forEach((ttsHandler) => {
      ttsHandler.removeEventListener(TTS_OPENED_EVENT, this.#handleTtsOpened);
      ttsHandler.removeEventListener(TTS_CLOSED_EVENT, this.#handleTtsClosed);
    });

    const bookmarkPaywallTriggers = document.querySelectorAll<HTMLElement>(
      "ws-paywallcontrol .feature-bar__button:has(.icon-bookmark)",
    );
    bookmarkPaywallTriggers.forEach((trigger) => {
      trigger.removeEventListener(
        "click",
        this.#handleForbiddenBookmarkAddEvents,
      );
    });

    const bookmarkButtons = document.querySelectorAll("ws-bookmarkbutton");
    bookmarkButtons.forEach((button) => {
      button.removeEventListener(
        BOOKMARK_TOGGLE_EVENT,
        this.#handleBookmarkButtonEvents,
      );
    });

    document.removeEventListener(
      ZEPHR_DECISIONS_FINISHED_EVENT,
      this.#paywallClickedListener,
    );

    document.removeEventListener(
      ZEPHR_DECISIONS_FINISHED_EVENT,
      this.#paywallLoginClickedListener,
    );

    document.removeEventListener(
      ZEPHR_DECISIONS_FINISHED_EVENT,
      this.#handleInlinePaywallVisibility,
    );
  }
}

customElements.get("ws-gtm") ??
  customElements.define("ws-gtm", GoogleTagManager);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-gtm": GoogleTagManager;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    dataLayer: any[];
  }
}
