export class Push extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  // Getter for the sdk attribute
  get sdk(): string {
    return this.getAttribute("sdk") || "";
  }

  // Getter for the worker attribute
  get worker(): string {
    return this.getAttribute("worker") || "";
  }

  connectedCallback() {
    // Add the script tag with sdk as src attribute
    if (this.sdk) {
      const script = document.createElement("script");
      script.src = this.sdk;
      script.async = true;
      this.shadowRoot!.appendChild(script);
    }

    // Register the service worker with worker as URL
    if (this.worker && "serviceWorker" in navigator) {
      navigator.serviceWorker
        .register(this.worker, {
          scope: "/",
        })
        .then((registration) => {
          console.log(
            "Service worker registered with scope: " + registration.scope,
          );
        })
        .catch((error) => {
          console.log("Service worker registration failed.");
          console.error(error);
        });
    }
  }
}

"customElements" in window &&
  customElements.get("ws-push") === undefined &&
  customElements.define("ws-push", Push);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-push": Push;
  }
}
