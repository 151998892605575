export type MessageHandlerEvent = CustomEvent<{
  adTagIds: string[];
}>;
export const MESSAGE_HANDLER_EVENT = "message_handler_event";
export const MESSAGE_HANDLER_INTERACTION_EVENT =
  "message_handler_interaction_event";

const customEvent: MessageHandlerEvent = new CustomEvent(
  MESSAGE_HANDLER_EVENT,
  {
    detail: {
      adTagIds: [
        "wallpaper_1",
        "superbanner_1",
        "skyscraper_1",
        "rectangle_1",
        "mobile_1",
      ],
    },
  },
);

const customInteractionEvent: CustomEvent = new CustomEvent(
  MESSAGE_HANDLER_INTERACTION_EVENT,
  {},
);

const config: Record<string, ServiceConfig> = {
  riddle: {
    origins: [/^https:\/\/www\.riddle\.com$/],
    ignoreMessage: (data: any) => {
      if (typeof data !== "object" || data === null) {
        return true; // Ignore if data is not an object or is null
      }
      return !(
        (
          ("riddleEvent" in data && data.riddleEvent === "page-change") || // riddle v1 events
          ("riddleEvent" in data &&
            data.riddleEvent.action.startsWith("answer-poll")) || // riddle v1 events
          ("isRiddle2Event" in data && data.action === "Block_Next") || // riddle v2 events
          ("isRiddle2Event" in data && data.action === "Block_Submit")
        ) // riddle v2 events
      );
    },
    adReloadInterval: 2,
    interactionCount: 0,
  },
  dpa: {
    origins: [
      /^https:\/\/embed\.dpa-electionslive\.com$/,
      /^https:\/\/ticker\.(capital|geo|stern)\.de$/,
    ],
    ignoreMessage: (data: any) =>
      (typeof data === "string" && data.startsWith("scrollToAnchor")) ||
      data?.request === "postUri" ||
      data?.type === "view",
    adReloadInterval: 2,
    interactionCount: 0,
  },
  games: {
    origins: [
      /^https:\/\/games\.softgames\.com$/,
      /^https:\/\/(dev\.)?weblications\.guj\.de$/,
      /^https:\/\/tools(\.dev)?\.(capital|geo|stern)\.de$/,
    ],
    ignoreMessage: (data: any) => {
      return (
        (data !== null &&
          typeof data === "object" &&
          "sentinel" in data &&
          (data.sentinel === "amp" || data.sentinel === "dpa") &&
          "type" in data &&
          data.type === "embed-size") ||
        (typeof data === "string" && data.startsWith("setIframeHeight"))
      );
    },
    adReloadInterval: 3,
    interactionCount: 0,
  },
  // Service-tools has extra dispatch logic (evaluateDispatchCondition)
  // The examples of real data is:
  // gujTracker.track.v1:::{"eventCategory":"Gaspreisrechner","eventAction":"click","eventLabel":"ergebnis_anzeigen"}
  // gujTracker.track.v1:::{"eventCategory":"Gaspreisrechner","eventAction":"click","eventLabel":"input-preis-kw"}
  // For the "Ernaehrungstool", only a "click" on the "ergebnis_anzeigen" button will trigger the AdReload logic.
  // For all the other tools, a "click" or "filter" action will trigger the AdReload logic.
  // Use a regular expression to extract parts of the 'data' string.
  // The first captured group consists of word characters or dots, followed by optional colons.
  // The second captured group captures the rest of the string.
  // We are interested only in the second captured group, hence the two commas at the start.
  // This group is assigned to 'filteredData', with a default value of null if no match is found.
  "service-tools": {
    origins: [
      new RegExp(window.location.origin.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")), // same origin
      /^https:\/\/www(\.dev|\.stage)?\.brigitte\.de$/,
    ],
    ignoreMessage: (data: any) => {
      return !(typeof data === "string" && data.startsWith("gujTracker"));
    },
    evaluateDispatchCondition: (data: any) => {
      const [, , filteredData = null] = data.match(/^([\w.]+):*(.*)$/);
      const dataObj = JSON.parse(filteredData);
      const { eventCategory, eventAction, eventLabel } = dataObj;
      return (
        (eventCategory === "Ernaehrungstool" &&
          eventAction === "click" &&
          eventLabel === "ergebnis_anzeigen") ||
        eventAction === "filter" ||
        eventAction === "click"
      );
    },
    adReloadInterval: 2,
    interactionCount: 0,
  },
};

type ServiceConfig = {
  origins: RegExp[];
  ignoreMessage: (data: any) => boolean;
  adReloadInterval: number;
  interactionCount: number;
  evaluateDispatchCondition?: (data: any) => boolean;
};

export class Messagehandler extends HTMLElement {
  get name() {
    return this.getAttribute("name");
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    window.addEventListener("message", this.#handleMessage);
  }

  disconnectedCallback() {
    window.removeEventListener("message", this.#handleMessage);
  }

  #isRelevantEvent(event: MessageEvent, serviceConfig: ServiceConfig): boolean {
    const { origin } = event;
    return serviceConfig.origins.some((allowedOrigin) =>
      new RegExp(allowedOrigin).test(origin),
    );
  }

  #handleMessage = (event: MessageEvent) => {
    const serviceName = this.name;
    const serviceConfig = config[serviceName as keyof typeof config];

    if (!serviceConfig) {
      console.warn(`No configuration found for service: ${serviceName}`);
      return;
    }

    if (this.#isRelevantEvent(event, serviceConfig)) {
      const { data } = event;

      if (
        this.name === "games" &&
        typeof data === "string" &&
        data.startsWith("gujTracker.restart")
      ) {
        window.location.reload();
      }

      if (!serviceConfig.ignoreMessage(data)) {
        serviceConfig.interactionCount += 1;
        this.dispatchEvent(customInteractionEvent);

        if (serviceConfig.interactionCount >= serviceConfig.adReloadInterval) {
          const shouldDispatch = serviceConfig.evaluateDispatchCondition
            ? serviceConfig.evaluateDispatchCondition(data)
            : true;

          if (shouldDispatch) {
            this.dispatchEvent(customEvent);
          }

          serviceConfig.interactionCount = 0;
        }
      }
    }
  };
}

customElements.get("ws-messagehandler") ??
  customElements.define("ws-messagehandler", Messagehandler);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-messagehandler": Messagehandler;
  }
}
